<template>
  <div>
    <Header message="/contact"></Header>
    <div class="box">
      <div class="game_studio">
        <div class="studio_left  wow animate__animated animate__zoomInUp">
          <div class="studio_name"><strong>{{ studio_name }}</strong></div>
          <h3 class="studio_declaration">{{ studio_declaration }}</h3>
          <br>
          <p class="brief_introduction">{{ brief_introduction }}</p>
          <br>
        </div>
        <div class="studio_right  wow animate__animated animate__fadeInDown">
          

          <div class="container pt-120 pb-120">
								<div class="row justify-content-center">
									<div class="col-lg-8">
										<div id="form" style="padding: 40px 30px;border-radius: 30px;background-color: #30405f;">
											<div class="row position-relative">
												<div class="col-md-6">
													<div class="form-control" style="margin-bottom: 0;padding: 0;padding-bottom: 20px;position: relative;background-color: #30405f;background-clip: padding-box;border: 1px solid transparent;">
														<label for="Name" style="font-size: 18px; font-weight: 700; color: #fff;margin-bottom: 15px;">Name</label>
														<input type="text" id="Name" placeholder="Enter Your Full Name" autocomplete="off" style="padding: 13px 20px;color: var(--bs-white);width: 100%;font-family: var(--para-font);border-radius: 10px;background-color: #425374;border: 1px solid #556f9c;outline-color: transparent;" >
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-control" style="margin-bottom: 0;padding: 0;padding-bottom: 20px;position: relative;background-color: transparent;background-clip: padding-box;border: 1px solid transparent;">
														<label for="email" style="font-size: 18px; font-weight: 700; color: #fff;margin-bottom: 15px;">Email</label>
														<input type="text" id="email" placeholder="Enter Your Email" autocomplete="off" style="padding: 13px 20px;color: var(--bs-white);width: 100%;font-family: var(--para-font);border-radius: 10px;background-color: #425374;border: 1px solid #556f9c;outline-color: transparent;">
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-control" style="margin-bottom: 0;padding: 0;padding-bottom: 20px;position: relative;background-color: #30405f;background-clip: padding-box;border: 1px solid transparent;">
														<label for="number" style="font-size: 18px; font-weight: 700; color: #fff;margin-bottom: 15px;">Phone</label>
														<input type="number" id="number" placeholder="Enter your Phone Number" style="padding: 13px 20px;color: var(--bs-white);width: 100%;font-family: var(--para-font);border-radius: 10px;background-color: #425374;border: 1px solid #556f9c;outline-color: transparent;">
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-control form_control" style="margin-bottom: 0;padding: 0;padding-bottom: 20px;position: relative;background-color: #30405f;background-clip: padding-box;border: 1px solid transparent;">
														<label for="subject" style="font-size: 18px; font-weight: 700; color: #fff;margin-bottom: 15px;">Subject</label>
														<input type="text" id="subject" placeholder="Enter Your Subject" autocomplete="off" style="padding: 13px 20px;color: var(--bs-white);width: 100%;font-family: var(--para-font);border-radius: 10px;background-color: #425374;border: 1px solid #556f9c;outline-color: transparent;">
													</div>
												</div>
												<div class="col-sm-12">
													<div class="form-control" style="margin-bottom: 0;padding: 0;padding-bottom: 20px;position: relative;background-color: transparent;background-clip: padding-box;border: 1px solid transparent;">
														<label for="message" style="font-size: 18px; font-weight: 700; color: #fff;margin-bottom: 15px;">Message</label>
														<textarea name="message" id="message" cols="10" rows="5" placeholder="Please type your message here..." style="padding: 13px 20px;color: var(--bs-white);width: 100%;font-family: var(--para-font);border-radius: 10px;background-color: #425374;border: 1px solid #556f9c;outline-color: transparent;"></textarea>
													</div>
												</div>
											</div>
											<div class="btn-area mt-3 text-center">
												<a href="mailto:inofullymetdines@icloud.com" class="" style="background: #487b00;color: #fff;padding: 8px 25px;border-radius: 4px;font-weight: 600;display: inline-block;">
													Send Message
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>













        </div>
      </div>


      
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'CONTACT',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      studio_name: 'get in touch',
      studio_declaration:"",
      brief_introduction: "We'd love to hear from you! Whether you have questions, feedback, or suggestions, our team is here to help.",
      data_display1:'110+',
      data_display2:'150+',
      data_display3:'9M+',
      data_display4:'2B',
      activeStep: 1,
      steps: [
        { title: '2018',step_text: 'HIGHLY INFORMED LLC was founded by five game engineers who shared a common dream of becoming leaders in mobile app technology.' },
        { title: '2021',step_text: "We've accomplished over 20 million downloads across global storefronts, securing impressive rankings as #13 and #20 on the free download charts." },
        { title: '2022',step_text: "We've released over 50 games, reaching a significant milestone of surpassing 50 million downloads worldwide." }
      ],
      images: [ 2018, 2021, 2022 ],
    }
  },
  computed: {
    currentImage() {
      if (this.activeStep==1) {
        return 2018;
      }else if(this.activeStep==2){
        return 2021;
      }else{
        return 2022
      }
    },
    steps_text(){
      return this.steps[this.images.indexOf(this.currentImage)].step_text;
    }
  },
  methods:{
    handleStepChange(newStep) {
      this.activeStep = newStep+1; // Adjust index because change event passes the new step index
    }
  }
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);
.game_studio {
  width: 80%;
  margin-top: .2604rem !important;
  padding: .5208rem 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  .studio_right{
      width: 60%;
      min-width: 400px;
  }
}
.studio_left{
  width: 60%;
  min-width: 400px;
  padding-top: .2604rem;
}
.studio_left>.studio_name{
  font-size: .1875rem;
  line-height: .1354rem;
  color: #FB9C2C;
}
.studio_left>.studio_declaration{
  font-size: .1875rem;
  line-height: .3229rem;
  color: #fff;
  padding: 0 0 .0521rem;
}
.studio_left>.brief_introduction{
  width: 2.8021rem;
  font-size: .0833rem;
  font-family: '楷书';
  line-height: .1563rem;
  color: #B3B3B3;
}
.studio_button{
  width: 1.3021rem;
  height: .3125rem;
  background-image: url(@/assets/images/button.png);
  background-size: cover;
  margin-top: .1563rem;
  text-align: center;
  line-height: .3125rem;
  font-size: .0938rem;
  color: #8F1209;
}
.studio_left>.studio_right{
  width: 2.8073rem;
  height: 2.2031rem;
}

.data_display{
  width: 5.8854rem;
  margin: .7813rem auto ;
  display: flex;
  justify-content: space-evenly;
}
.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .2083rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: .2708rem;
    line-height: .3802rem;
    color: #fff;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: .1667rem;
    line-height: .2188rem;
  }
}
.border_left{
  border-left: .026rem solid #FB9C2C;
  text-align: left;
}
// 步骤条
.image-container {
  width: 5.9375rem;
  height: 3.6458rem;
  text-align: center;
}
 
.image-container>img {
  max-width: 5.9115rem;
  max-height: 3.3281rem;
}

</style>