<template>
  <div class="header">
      <!-- 顶部导航栏 -->
      <div class="top-nav sticky">
        <div style=" width: .7813rem; height: .3646rem;">
          <img src="@/assets/images/logo.png" style=" width: .2604rem; height: .1849rem ;margin: .1042rem .2604rem;" alt="" />
        </div>
        <div>
          <el-menu :default-active="message" class="el-menu-demo" mode="horizontal" @select="handleSelect"
            background-color="#000" text-color="#fff"   active-text-color="#f36229">
            <el-menu-item index="/home" :class="message==='/home'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="$route.name.includes('uncti')?'/uncti/home':'/home'" style="text-decoration: none;">Home</router-link>
            </el-menu-item>
            <el-menu-item index="/game" :class="message==='/game'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="$route.name.includes('uncti')?'/uncti/game':'/game'" style="text-decoration: none;">Game</router-link>
            </el-menu-item>
            
            <el-menu-item index="/publishing" :class="message==='/publishing'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="$route.name.includes('uncti')?'/uncti/publishing':'/publishing'" style="text-decoration: none;">Publishing</router-link>
            </el-menu-item>
            <el-menu-item index="/career" :class="message==='/career'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="$route.name.includes('uncti')?'/uncti/career':'/career'" style="text-decoration: none;">Career</router-link>
            </el-menu-item>
            <el-menu-item index="/aboutus" :class="message==='/aboutus'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="$route.name.includes('uncti')?'/uncti/aboutus':'/aboutus'" style="text-decoration: none;">About Us</router-link>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="top-nav-right" @click="submitGame">
          Contact Us
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name:'HEADER',
  props: {
    message: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      scrollTop: 0,  
      headerHeight: 0, 
    }
  },  
  methods:{
    submitGame(){
      this.$router.push({ 'path': "contact.html" }).catch(() => { })
    },
    handleSelect(key) {
      this.$router.push({ 'path': key }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import url(../fontCss/fontCss.css);
.header {
  transition: top 1s ease; 
  background-color: #000;
  width: 100%;
  height: .3646rem;
  position: fixed;
  top: 0;
  z-index: 999;
}
.top-nav {
  display: flex;
  justify-content: center;
  width: 8.6979rem;
  margin: auto;
  height: 100%;
  margin-bottom: .1563rem;
}
.el-menu-item{
  a{
    font-size: .1042rem;
  }
}
.el-menu-item{
  border-bottom-color: 000;
}

.el-menu.el-menu--horizontal {
    border-bottom: none;
}
.el-menu-demo{
  height: .2604rem !important;
  width: 4.1667rem !important;
  padding-top: .0521rem;
  font-size: .0833rem !important;
  font-weight: bold;
  font-family:'SVN-Avant-book';
  text-align: center;
}
.el-menu--horizontal>.el-menu-item {
  height: .3125rem !important;
  line-height: .3125rem !important;
  width: .7813rem !important;
  margin: 0 auto;
}
.el-menu-vertical {
  height: .2604rem !important; /* 设置导航栏的高度 */
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: #000 !important;
  color: #ffffffc9 !important;
}
.sticky {
  position: sticky;
  top: 0;
  left: 0;
}
.active>a{
  padding: .026rem;
  border-radius: .026rem;
}
.top-nav-right{
  cursor: pointer;
  line-height: .3646rem;
  color: #8F1209;
  font-size: .0833rem;
  padding: 0 .1432rem .0677rem;
  background-color: #ED9A21;
  transition: color .5s;
  user-select: none;
}
.top-nav-right:hover{
  color: #fff;
}
</style>